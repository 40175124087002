import { Velustro } from "uvcanvas";

import React, { useContext, useState } from "react";
import { ThemeContext } from "../ThemeProvider";
import { FiSun, FiMoon } from "react-icons/fi";
import { Link } from "react-scroll";

//Images
import Logo from "../assets/images/logo_ribera.png";



const Header = () => {
  const { theme, toggleTheme } = useContext(ThemeContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  return (
    <>
      <div className="relative w-full h-[400px] lg:h-[500px]">
        <Velustro />
        <div className="absolute top-0 left-0 right-0">
          <div className="px-4 mx-auto max-w-7xl sm:px-6">
            <div className="pt-6 pb-6 sm:pb-24">
              <nav className="relative flex items-center justify-between" aria-label="Global">
                <div className="flex justify-start flex-1">
                  <a href="/">
                    <img src={Logo} className="w-[150px] xl:w-[200px]" alt="Logo RiberaDev" />
                  </a>
                </div>
                <div className='hidden md:flex md:space-x-10'>
                  <Link to="history" smooth={true} duration={500} className="hover:cursor-pointer text-base font-semibold text-white hover:text-gray-900">
                    À propos
                  </Link>
                  <Link to="services" smooth={true} duration={500} className="hover:cursor-pointer text-base font-semibold text-white hover:text-gray-900">
                    Services
                  </Link>
                </div>
                <div className="flex items-center justify-end flex-1 space-x-4">
                <div className="flex items-center -mr-2 md:hidden">
                    <button
                      onClick={toggleMenu}
                      className="inline-flex p-2 text-gray-400 bg-black rounded-md hover:text-white hover:bg-black focus:outline-none focus:ring-2 focus:ring-inset focus:ring-gray-50"
                      aria-expanded={isMenuOpen}
                      type="button"
                    >
                      <span className="sr-only">Open main menu</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        aria-hidden="true"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d={`M4 ${isMenuOpen ? "19" : "6"}h16M4 12h16m-16 ${isMenuOpen ? "7" : "6"}h16`}
                        ></path>
                      </svg>
                    </button>
                  </div>
                  {/* Mobile menu, show/hide based on menu state. */}
                  <div className={`${isMenuOpen ? 'block' : 'hidden'} md:hidden absolute right-0 top-full mt-2 py-3 w-full bg-black text-white rounded-md shadow-lg`}>
                    <div className="px-1 pt-1 pb-3 space-y-1 sm:px-3 bg-black text-white text-center rounded-md shadow-lg">
                      {/* Utilise Link de react-scroll pour le défilement doux */}
                      <Link to="history" smooth={true} duration={500} className=" hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium">
                        À propos
                      </Link>
                      <Link to="services" smooth={true} duration={500} className="hover:cursor-pointer hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium">
                        Services
                      </Link>
                      <Link to="contact" smooth={true} duration={500} className="hover:cursor-pointer hover:bg-gray-700 text-white block px-3 py-2 rounded-md text-base font-medium">
                        Contactez-nous
                      </Link>
                    </div>
                  </div>
                  <div className="hidden md:inline-flex rounded-full shadow">
                    <Link
                      to="contact" smooth={true} duration={500}
                      className="inline-flex items-center px-4 py-2 text-base text-gray-900 bg-white border border-transparent rounded-full cursor-pointer font-base hover:bg-gray-50"
                    >
                      Contactez-nous
                    </Link>
                  </div>
                  <div className="inline-flex rounded-full shadow">
                    <button onClick={toggleTheme} className="text-2xl text-white">
                      {theme === "dark" ? <FiSun /> : <FiMoon />}
                    </button>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
