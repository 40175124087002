import React, { useState, Suspense, useRef } from 'react';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { useLoader, useFrame, Canvas } from '@react-three/fiber';
import { OrbitControls, PerspectiveCamera, Html } from "@react-three/drei";


//Email
import emailjs from 'emailjs-com';
import { toast, ToastContainer, Bounce } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Composant Lumière (Soleil)
const SunLight = () => {
    return (
        <>
            <ambientLight intensity={0.5} />
            <directionalLight
                position={[20, 10, 20]} // Exemple de position, ajustez selon vos besoins
                intensity={1.5}
                castShadow
            />
        </>
    );
};

// Composant Modèle
const Model = () => {
    const modelRef = useRef();
    const gltf = useLoader(GLTFLoader, 'assets/threejs/rocket1.glb', loader => {
        const dracoLoader = new DRACOLoader();
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/'); // Assurez-vous d'ajuster le chemin
        loader.setDRACOLoader(dracoLoader);
    });
    gltf.scene.position.y = -20;
    useFrame(() => {
        if (modelRef.current) {
            modelRef.current.rotation.y += 0.002;
        }
    });
    return <primitive ref={modelRef} object={gltf.scene} scale={1} />;
};

const Contact = () => {
    const numberOfStars = 660;
    const stars = Array.from({ length: numberOfStars }).map((_, index) => {
        const style = {
            left: `${Math.random() * 100}%`,
            top: `${Math.random() * 100}%`,
            animationDelay: `${Math.random() * 20}s`,
        };
        return <div key={index} className="star" style={style}></div>;
    });

    const [message, setMessage] = useState("");
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_3z2qe3j', 'template_ad4skg9', e.target, 'g-NEnHYqixdwMoV__')
            .then((result) => {
                console.log(result.text);
                setMessage("");
                toast.success('🚀  Votre message a été envoyé avec succès !', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce
                });
                e.target.reset();
            }, (error) => {
                console.log(error.text);
                toast.error('🚨 Une erreur s’est produite lors de l’envoi du message.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: true,
                    progress: undefined,
                    theme: "dark",
                    transition: Bounce
                });
            });
    };

    return (
        <div>
            <section className='bg-black w-full relative'>
                <div className="text-center mb-8 z-10">
                    <h1 className="text-3xl sm:text-4xl md:text-6xl lg:text-7xl xl:text-8xl font-bold text-center mt-5 text-white">
                        Contactez<span className="text-blue-500">-nous</span>
                    </h1>
                </div>
                <div className="flex flex-col lg:flex-row justify-center lg:justify-between items-center mt-10 mx-4 sm:mx-6 md:mx-8 lg:mx-8 xl:mx-12 2xl:mx-28">
                    <div className="bg-white p-8 rounded-lg shadow-lg w-full lg:max-w-lg xl:max-w-xl 2xl:max-w-2xl mb-8 lg:mb-0 lg:mr-8 mx-auto">
                        <form onSubmit={sendEmail} ref={form}>
                            <div className="mb-4">
                                <label htmlFor="from_name" className="block text-sm font-medium text-gray-700">Nom / Prénom</label>
                                <input type="text" id="from_name" name="from_name" className="border-2 text-black border-gray-300 p-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent" placeholder="John Doe" required autoComplete="name" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="from_email" className="block text-sm font-medium text-gray-700">Email</label>
                                <input type="email" id="from_email" name="from_email" className="border-2 text-black border-gray-300 p-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent" placeholder="johndoe@example.com" required autoComplete="email" />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="subject" className="block text-sm font-medium text-gray-700">Sujet</label>
                                <input type="text" id="subject" name="subject" className="border-2 text-black border-gray-300 p-2 rounded-lg w-full focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent" placeholder="Sujet du message" required />
                            </div>
                            <div className="mb-4">
                                <label htmlFor="message" className="block text-sm font-medium text-gray-700">Message</label>
                                <textarea id="message" name="message" className="border-2 text-black border-gray-300 p-2 rounded-lg w-full h-32 resize-none focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent" placeholder="Votre message" required></textarea>
                            </div>
                            <button type="submit" className="bg-indigo-500 text-white p-2 rounded-lg font-semibold w-full hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">Envoyer !</button>
                        </form>
                    </div>
                    <div className='flex-none w-full lg:w-1/2 xl:w-3/5 h-full xl:h-[700px] 2xl:h-[800px] canvas-container'>
                        <Canvas>
                            <Suspense fallback={<Html><div>Loading...</div></Html>}>
                                <PerspectiveCamera makeDefault position={[-400, 10, 0]} />
                                <SunLight />
                                <Model />
                                <OrbitControls
                                    enableZoom={false}
                                    maxPolarAngle={Math.PI / 2}
                                    minPolarAngle={Math.PI / 2}
                                />
                            </Suspense>
                        </Canvas>
                    </div>
                </div>
                {stars}
            </section>
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable
                pauseOnHover={false}
                theme="dark"
                transition={'Bounce'}
            />
        </div>
    );
}

export default Contact;
