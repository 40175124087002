import React from "react";

const Footer = () => {

  return (
    <footer className="dark:bg-white rounded-lg shadow m-4 p-4">
      <div className="max-w-screen-xl mx-auto flex flex-col items-center justify-center text-center">
        <span className="text-sm dark:text-black mb-2">
          © 2024{" "}
          <a href="https://ribera-dev.fr" className="hover:underline">
            RiberaDev™
          </a>
          . Tous droits réservés.
        </span>
        <div className="text-sm dark:text-black mb-2">
          Made with ❤️ by{" "}
          <span className="text-blue-500 hover:underline font-medium">
            Manny
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
