import React, { Suspense, useRef } from "react";
import { Canvas, useLoader, useFrame } from "@react-three/fiber";
import { OrbitControls, PerspectiveCamera, Html } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader";


//Images
import Prestashop from "../assets/images/icons/prestashop.png";
import Web from "../assets/images/icons/web.png";
import App from "../assets/images/icons/app.png";
import Cyber from "../assets/images/icons/cyber.png";


const Model3D = () => {

  const modelRef = useRef();

  const gltf = useLoader(GLTFLoader, "/assets/threejs/iphone_15_pro-c.glb", loader => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/versioned/decoders/1.5.7/');
    loader.setDRACOLoader(dracoLoader);
    console.log(loader.setDRACOLoader(dracoLoader));
  });

  gltf.scene.position.y = 0;

  useFrame(() => {
    if (modelRef.current) {
      modelRef.current.rotation.y += 0.006;
    }
  });
  return <primitive ref={modelRef} object={gltf.scene} scale={[4, 4, 4]} />;
};


const Services = () => {
  return (
    <div className="container mx-auto px-4 py-8">
      <div className="text-center mb-8">
        <h1 className="text-3xl md:text-6xl lg:text-7xl font-bold text-center mt-5">
          Nos <span className="text-blue-500">Services</span>
        </h1>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-center">
        {/* Canvas de Three.js pour le modèle 3D */}
        <div className="w-96 h-96 lg:h-[600px] lg:w-1/2 flex justify-center lg:justify-start">
          <Canvas>
            <Suspense
              fallback={
                <Html>
                  <div>Chargement du modèle...</div>
                </Html>
              }
            >
              <PerspectiveCamera makeDefault position={[1, 0, 0]} fov={37} />
              <ambientLight intensity={3.5} />
              <directionalLight intensity={1.5} position={[10, 1, 0]} />
              <Model3D />
              <OrbitControls
                enableZoom={false}
                enablePan={true}
                maxPolarAngle={Math.PI / 1}
                minPolarAngle={Math.PI / 2}
              />
            </Suspense>
          </Canvas>
        </div>



        {/* Liste d'icônes et de textes */}
        <div className="w-full mt-24 lg:w-1/2 flex flex-col items-center lg:items-start pl-0 lg:pl-8">
          <div className="flex items-center mb-10 space-x-6">
            <img
              src={Prestashop}
              alt="Prestashop"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-22 xl:h-22"
            />
            <span className="">
              Je fais du développement de modules de Prestashop. Ce type de
              service consiste à développer un module spécifique pour votre
              boutique, l’adapter à vos besoins. J’ai acquis beaucoup
              d’expérience dans l’optimisation de l’expérience client,
              l’avancement des ventes, faisant des sites web plus efficaces et
              utiles pour les clients.
            </span>
          </div>
          <div className="flex items-center mb-10 space-x-6">
            <img
              src={Web}
              alt="Prestashop"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-22 xl:h-22"
            />
            <span className="">
              Je me spécialise dans le développement et la création de sites web
              sur mesure, répondant précisément aux exigences spécifiques de
              chaque client. Mon approche personnalisée garantit des solutions
              web uniques, performantes et adaptées, visant à optimiser la
              présence en ligne et à maximiser l'engagement utilisateur.
            </span>
          </div>
          <div className="flex items-center mb-10 space-x-6">
            <img
              src={App}
              alt="Prestashop"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-22 xl:h-22"
            />
            <span className="">
              Je conçois et développe des applications mobiles sur mesure,
              transformant des idées en solutions innovantes et interactives. Ma
              démarche personnalisée assure des applications performantes,
              intuitives et adaptées aux besoins uniques de chaque client,
              améliorant ainsi l'engagement des utilisateurs et valorisant leur
              expérience mobile.
            </span>
          </div>
          <div className="flex items-center mb-10 space-x-6">
            <img
              src={Cyber}
              alt="Prestashop"
              className="w-16 h-16 sm:w-20 sm:h-20 md:w-20 md:h-20 lg:w-20 lg:h-20 xl:w-22 xl:h-22"
            />
            <span className="">
              Je propose des services de cybersécurité, protégeant les
              entreprises contre les menaces numériques. Mon expertise couvre la
              sécurisation des systèmes, la prévention des intrusions, et la
              gestion des risques, assurant une défense robuste contre les
              cyberattaques. Je travaille à sécuriser vos données et à préserver
              votre tranquillité d'esprit.
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
