import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// Components
import Header from "./components/Header";
import Footer from "./components/Footer";
import History from "./components/History";
import Services from "./components/Services";
import Contact from "./components/Contact";
import BackToTheTop from './BackToTheTop';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={
          <>
            <Header />
            <div id="history"><History /></div>
            <div id="services"><Services /></div>
            <div id="contact"><Contact /></div>
            <BackToTheTop />
            <Footer />
          </>
        } />
      </Routes>
    </Router>
  );
}

export default App;
