import React from "react";

// Images
import First from "../assets/images/first.jpg";
import Second from "../assets/images/second.jpg";
import Third from "../assets/images/third.jpg";

const History = () => {
  return (
    <div>
      <section className="w-full mb-38 md:mb-56 lg:mb-[100px]">
        <div className="max-w-4xl mx-auto px-4">
          <h1 className="text-3xl md:text-6xl lg:text-7xl font-bold text-center mt-5">
            À propos de <span className="text-blue-500">Ribera</span>Dev
          </h1>
          <div className="mt-3">
            <div className="container mx-auto flex flex-col md:flex-row items-center py-12 px-4">
              <div className="mb-12 md:mb-0 md:w-1/2">
                <h2 className="text-2xl md:text-4xl lg:text-5xl font-bold mb-6">
                  <span className="text-blue-500">Comment l'</span>aventure a commencé ?
                </h2>
                <p className="text-lg md:text-xl mb-6">
                  Fondée en 2023, RiberaDev incarne le renouveau dans le secteur technologique. Guidée par la liberté d'innovation, notre entreprise dynamique est l'œuvre d'une <span className="text-blue-500 underline">équipe jeune</span> et passionnée. Nous nous engageons à repousser les limites du possible, offrant des solutions novatrices qui reflètent notre <span className="text-blue-500 underline">vision audacieuse</span>. RiberaDev est plus qu'une entreprise; c'est <span className="text-blue-500 underline">une promesse de transformation et d'évolution</span> constante dans l'univers du développement
                </p>
              </div>
              <div className="xl:relative xl:w-1/2 xl:ml-auto">
                <div className="flex flex-col lg:flex-col lg:items-center xl:absolute xl:inset-0 items-center xl:items-end">
                  <img
                    src={First}
                    className="mb-4 xl:mb-0 w-full xl:absolute xl:top-[-80px] xl:right-[-390px] xl:z-30"
                    alt="Première"
                  />
                  <img
                    src={Second}
                    className="mb-4 xl:mb-0 w-full xl:absolute xl:top-14 xl:right-[-50px] xl:z-20"
                    alt="Deuxième"
                  />
                  <img
                    src={Third}
                    className="w-full xl:absolute xl:bottom-[-2px] xl:right-[-50px] xl:z-10"
                    alt="Troisième"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default History;
